// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-book-demo-js": () => import("/opt/build/repo/src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-product-js": () => import("/opt/build/repo/src/pages/our-product.js" /* webpackChunkName: "component---src-pages-our-product-js" */),
  "component---src-pages-what-are-missions-js": () => import("/opt/build/repo/src/pages/what-are-missions.js" /* webpackChunkName: "component---src-pages-what-are-missions-js" */),
  "component---src-pages-why-edwin-js": () => import("/opt/build/repo/src/pages/why-edwin.js" /* webpackChunkName: "component---src-pages-why-edwin-js" */)
}

